import _ from 'lodash'
import { colors, createTheme as createMuiTheme, responsiveFontSizes } from '@material-ui/core'
import { itIT } from '@material-ui/core/locale'
import { THEMES } from '../constants'
import { softShadows } from './shadows'
import typography from './typography'

// variabili dei colori
export const basicColors = {
  darkGreen: '#4caf50',
  lightGreen: '#C8F6CC',
  darkYellow: '#ff9800',
  lightYellow: '#FFF3E0',
  darkRed: '#f44336',
  lightRed: '#FFE0E0',
  lightBlue: '#d3e3ff',
  lightBlue2: '#E8F1F4',
  blueGrayDark: '#263238',
  blueGrayMedium: '#546E7A',
  blueGrayLight: '#B0BEC5',
  background: '#182638',
  backgroundGradient: 'linear-gradient(90deg, rgba(24,38,56,1) 0%, rgba(12,12,92,1) 44%, rgba(2,20,105,1) 100%)',
  nav: '#263d54',
  darkText: '#111111',
  whiteText: '#ffffff',
  secondary: '#4e87cc',
  primaryEnea: '#00CEC9',
  secondaryEnea: '#004984',
  backgroundEnea: '#ebf1f5'
}

const baseOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    },
    MuiButton: {
      root: {
        borderRadius: 16
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 16
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: '8px'
      }
    }
  }
}

// Doc for customizations
// https://material-ui.com/customization/default-theme/?expand-path=$.palette
// https://material-ui.com/customization/palette/
const themesOptions = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: '#1D6F9B'
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white
      },
      primary: {
        main: '#00CEC9',
        alternative: '#1D6F9B'
      },
      secondary: {
        main: '#004984',
        alternative: '#013B69'
      },
      alert: {
        error: basicColors.darkRed,
        warning: '#E4C548'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      }
    },
    shadows: softShadows,
    redButton: {
      backgroundColor: colors.red[700],
      color: 'white',
      '&:hover': {
        backgroundColor: basicColors.darkRed
      }
    },
    greenButton: {
      backgroundColor: colors.green[600],
      color: 'white',
      '&:hover': {
        backgroundColor: colors.green[800]
      }
    }
  }
]

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme)

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`))
    ;[themeOptions] = themesOptions
  }

  let theme = createMuiTheme(_.merge({}, baseOptions, themeOptions, { direction: config.direction }), itIT)

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme
}
