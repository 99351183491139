import { createSlice } from '@reduxjs/toolkit'
import api from '@micmnt/apis'

import { decodeFromApi } from '@utils/adapters/smartbuilding/plantAdapter'

// Stato iniziale di un impianto di tipo smartHome
const defaultPlant = {
  gettingData: true,
  hasError: null,
  hasPhotovoltaic: false,
  hasExchangeMeter: false,
  hasBatteries: false,
  currentTab: 'overview',
  uuid: '',
  name: '-',
  plantType: '-',
  contractPower: '-',
  tenants: '-',
  surface: '-',
  userEmail: '-',
  isGreenEnergy: 'false',
  photovoltaicData: {},
  batteryData: [],
  productionData: [],
  aggregatedData: [],
  autoconsumptionData: [],
  hourlyAutoconsumptionData: [],
  exportedEnergyData: [],
  importedEnergyData: [],
  aggregatedHourlyData: [],
  exportedHourlyEnergyData: [],
  importedHourlyEnergyData: [],
  aggregatedMonthData: [],
  disaggregatedData: [],
  linesData: [],
  address: {
    inputValue: ''
  },
  dataloggers: ['-'],
  devices: [],
  assetTypes: [],
  assets: []
}

// Slice con reducers
const slice = createSlice({
  name: 'plantView',
  initialState: JSON.parse(JSON.stringify(defaultPlant)),
  reducers: {
    changeSmartBuildingValue (state, action) {
      const { value, key } = action.payload
      state[key] = value
    },
    setSmartBuilding (state, action) {
      const { newPlant, hasError, newDataloggers, newAssetTypes, newAssets, newDevices } = action.payload
      if (hasError) {
        state.hasError = hasError
      } else {
        // resetto l'errore
        state.hasError = null
        // Aggiorno l'impianto
        state.uuid = newPlant?.uuid || defaultPlant.uuid
        state.isGreenEnergy = newPlant?.isGreenEnergy || defaultPlant.isGreenEnergy
        state.hasPhotovoltaic = newPlant.hasPhotovoltaic || defaultPlant.hasPhotovoltaic
        state.hasExchangeMeter = newPlant.hasExchangeMeter || defaultPlant.hasExchangeMeter
        state.hasBatteries = newPlant.hasBatteries || defaultPlant.hasBatteries
        state.name = newPlant?.name || defaultPlant.name
        state.dataloggers = newDataloggers || defaultPlant.dataloggers
        state.devices = newDevices || defaultPlant.devices
        state.plantType = newPlant?.plantType || defaultPlant.plantType
        state.address = newPlant?.address || defaultPlant.address
        state.contractPower = newPlant?.contractPower || defaultPlant.contractPower
        state.tenants = newPlant?.tenants || defaultPlant.tenants
        state.surface = newPlant?.surface || defaultPlant.surface
        state.userEmail = newPlant?.userEmail || defaultPlant.userEmail
        state.assetTypes = newAssetTypes || defaultPlant.assetTypes
        state.assets = newAssets || defaultPlant.assets
        // Imposto che ho preso i dati per la prima volta
        state.gettingData = false
      }
    }
  }
})

export const { reducer } = slice

// Funzione che gestisce il cambio delle tabs
export const handleTabsChange = (newTab) => dispatch => {
  dispatch(slice.actions.changeSmartBuildingValue({ value: newTab, key: 'currentTab' }))
}

// Funzione che azzera l'impianto
export const resetPlantInfo = () => dispatch => {
  const valuesToReset = Object.keys(defaultPlant).map(key => ({
    key,
    value: defaultPlant[key]
  }))

  valuesToReset.forEach(el => {
    const { value, key } = el
    dispatch(slice.actions.changeSmartBuildingValue({ value, key }))
  })
}

// Funzione che aggiorna l'impianto mostrato
export const updatedPlantInfo = ({ filter = null, plantId }) => async dispatch => {
  // Preparo i parametri per la GET dell'impianto
  const params = {}
  if (filter) { params.filter = filter }
  let hasError = null

  // Eseguo la GET dell'impianto
  const { data: fullData, error } = await api.get({
    savedUrl: 'plantDetails',
    params,
    path: `/${plantId}`
  })
  // Gestisco l'errore dove presente
  if (error) {
    hasError = 'generic'
  }

  // Eseguo la GET per prendere tutti gli asset Types
  const { data: assetTypes, error: assetTypesError } = await api.get({
    savedUrl: 'assetTypes'
  })

  // Gestisco l'errore per gli asset types dove presente
  if (assetTypesError) {
    hasError = 'generic'
  }

  // Manipolo i dati per averli nella forma giusta per la visualizzazione
  const { plant } = decodeFromApi({
    plant: fullData.plant,
    dataloggers: fullData.dataloggers,
    assets: fullData.assets,
    devices: fullData.devices,
    assetTypes
  })

  dispatch(slice.actions.setSmartBuilding({
    newPlant: plant,
    newDataloggers: plant.addedDataloggers,
    newDevices: plant.devices,
    newAssetTypes: plant.assetTypes,
    newAssets: plant.assets,
    hasError
  }))
}

// Funzione che aggiorna i dati dei grafici in overview
export const updateChartData = ({ data = [], propKey }) => dispatch => {
  dispatch(slice.actions.changeSmartBuildingValue({ value: data, key: propKey }))
}
