import { useRef, useState, memo } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import {
  Avatar,
  Paper,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  useMediaQuery,
  makeStyles,
  Box,
  Grid,
  Tooltip,
  ListItem
} from '@material-ui/core'
import useAuth from '@hooks/useAuth'

import {
  User as UserIcon,
  LogOut as OutIcon
} from 'react-feather'
import { configuration } from '@/config'
const placeholder = '/static/images/avatars/user-placeholder2.svg'
const avatarWidth = 32

const useStyles = makeStyles((theme) => ({
  extendedContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 16,
    left: 10,
  },
  avatarContainer: {
    marginRight: 'auto',
    marginLeft: 'auto',
    borderRadius: 24,
    position: 'absolute',
    bottom: 16,
    left: 10,
  },
  mobileAvatarContainer: {
    borderRadius: 24,
    position: 'absolute',
    bottom: 16,
    right: theme.spacing(3) + 2,
    [theme.breakpoints.down('xs')]: {
      bottom: 12
    }
  },
  menuItem: {
    marginBottom: 6
  },
  menuIcon: {
    marginRight: 6
  },
  avatar: {
    height: avatarWidth,
    width: avatarWidth
  },
  popover: {
    minWidth: 200,
    marginTop: -72,
  }
}))

const Settings = ({ extended = false }) => {
  const classes = useStyles()
  const history = useHistory()
  const ref = useRef(null)
  const { user, logout } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const [isOpen, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = async () => {
    try {
      handleClose()
      await logout()
      console.clear()
      if (configuration.environment === 'production') {
        window.location.assign(`${configuration.authPlatformLogout}`)
      } else {
        history.push('/login')
      }
    } catch (err) {
      console.error(err)
      enqueueSnackbar('Impossibile eseguire il logout. Per favore controlla la tua connessione.', {
        variant: 'error'
      })
    }
  }

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <>
      {extended
        ? (
          <ListItem onClick={handleOpen} ref={ref} className={classes.extendedContainer} button>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Paper
                  display='flex'
                  className={!isSmall ? classes.avatarContainer : classes.mobileAvatarContainer}
                  elevation={2}
                >
                  <Avatar
                    alt='User'
                    className={classes.avatar}
                    src={user.metadata && user.metadata.imageURL ? user.metadata.imageURL : placeholder}
                  />
                </Paper>
              </Grid>
              <Grid item xs={10}>
                {user.fullName && user.email
                  ? (
                    <Box ml={1} textAlign='left'>
                      <Tooltip title={user.fullName}>
                        <Typography noWrap style={{ color: '#fff' }} variant='h4'>{user.fullName}</Typography>
                      </Tooltip>
                      <Tooltip title={user.email}>
                        <Typography noWrap style={{ color: '#f3f3f3' }} variant='caption'>{user.email}</Typography>
                      </Tooltip>
                    </Box>
                    )
                  : <Typography variant='h4'>{user.email || ''}</Typography>}
              </Grid>
            </Grid>
          </ListItem>
          )
        : (
          <Paper
            display='flex'
            onClick={handleOpen}
            // onMouseEnter={handleOpen}
            // onMouseLeave={handleClose}
            ref={ref}
            className={!isSmall ? classes.avatarContainer : classes.mobileAvatarContainer}
            elevation={2}
          >
            <Avatar
              alt='User'
              className={classes.avatar}
              src={user.metadata && user.metadata.imageURL ? user.metadata.imageURL : placeholder}
            />
          </Paper>
          )}
      {/* menu */}
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: extended ? 'top' : 'bottom',
          horizontal: extended ? 'left' : 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem disabled className={classes.menuItem}>
          Salve {user.fullName}
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          component={RouterLink}
          to='/app/settings/account'
        >
          <ListItemIcon className={classes.menuIcon}>
            <UserIcon fontSize='small' />
          </ListItemIcon>
          <Typography variant='inherit' noWrap>Account</Typography>
          {/* <ListItemText primary="Account" /> */}
        </MenuItem>
        <MenuItem onClick={handleLogout} className={classes.menuItem}>
          <ListItemIcon className={classes.menuIcon}>
            <OutIcon fontSize='small' />
          </ListItemIcon>
          <Typography variant='inherit' noWrap>Logout</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

export default memo(Settings)
