import { useEffect, memo } from 'react'
import NProgress from 'nprogress'
import {
  Box,
  LinearProgress,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import clsx from 'clsx'
import { basicColors } from '@/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100vh',
    padding: theme.spacing(3)
  },
  defaultBackground: {
    backgroundColor: theme.palette.background.dark
  },
  secondaryBackground: {
    backgroundColor: basicColors.background
  },
  glassBackground: {
    '@supports (backdrop-filter: none) or (-webkit-backdrop-filter: none)': {
      '-webkit-backdrop-filter': 'saturate(120%) blur(10px)',
      backdropFilter: 'saturate(120%) blur(16px)',
      backgroundColor: 'rgba(16, 26, 38, 0.5)'
    }
  }
}))

const LoadingScreen = ({ glass = false, dark = false }) => {
  const classes = useStyles()
  const theme = useTheme()
  // rimozione del loader
  NProgress.configure({
    showSpinner: false,
    template: `<div class="bar" role="bar" style="background: ${theme.palette.primary.main}; height: 3px;"><div class="peg" style="box-shadow: 0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>`
  })

  useEffect(() => {
    NProgress.start()

    return () => {
      NProgress.done()
    }
  }, [])

  const composeClasses = (hasGlass, isDark) => {
    const key = hasGlass ? 'hasGlass' : isDark ? 'isDark' : 'default'

    const map = {
      hasGlass: clsx(classes.root, classes.glassBackground),
      isDark: clsx(classes.root, classes.secondaryBackground),
      default: clsx(classes.root, classes.defaultBackground)
    }

    return map[key] || map.default
  }

  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))

  return (
    <div className={composeClasses(glass, dark)}>
      <Box width={isSmall ? 200 : 400}>
        <LinearProgress />
      </Box>
    </div>
  )
}

export default memo(LoadingScreen)
