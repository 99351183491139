import { createSlice } from '@reduxjs/toolkit'
import { decodeFromApi, encodeForApi } from '@utils/adapters/smarthome/plantAdapter'
import api from '@micmnt/apis'

const initialState = {
  name: '',
  loadData: false,
  activeStep: 0,
  plantType: 'smarthome',
  dataloggers: [],
  devices: [],
  aggregators: [],
  assetTypes: [],
  districts: [],
  addedDataloggers: [],
  addedAssets: [],
  address: {},
  maintenance: true,
  tenants: 0,
  surface: 0,
  contractType: '',
  energyMarket: '',
  contractPower: 0,
  priceType: '',
  userEmail: '',
  districtId: '',
  aggregatorId: '',
  userType: 'consumer',
  isPreM2Battery: 'false',
  haveProductionMeter: 'false',
  haveDisaggregation: 'false',
  haveClustering: 'false',
  isGreenEnergy: 'false',
  tenantsPresence: {
    morning: 0,
    afternoon: 0,
    evening: 0,
    night: 0
  }
}

const slice = createSlice({
  name: 'smarthomePlant',
  initialState,
  reducers: {
    setLoadData(state, actions) {
      const { loading } = actions.payload
      state.loadData = loading
    },
    resetPlant(state) {
      const initialStateKeys = Object.keys(initialState)

      if (state.uuid) {
        delete state.uuid
      }

      initialStateKeys.forEach(key => {
        state[key] = initialState[key]
      })
    },
    setCurrentPlant(state, actions) {
      const { plant } = actions.payload

      const plantKeys = Object.keys(plant)

      plantKeys.forEach(key => {
        state[key] = plant[key] !== undefined ? plant[key] : initialState[key]
      })

      state.loadData = false
    },
    toggleMaintenance (state, action) {
      const { maintenance } = action.payload
      state.maintenance = maintenance
    },
    setStep(state, action) {
      const { step } = action.payload
      state.activeStep = step
    },
    setBuildingValue(state, action) {
      const { value, key } = action.payload
      state[key] = value
    },
    removeDatalogger(state, action) {
      const { dataloggerId } = action.payload
      // Datalogger disponibili
      const currentDataloggers = [...state.dataloggers]
      // Datalogger assegnati all'impianto
      const addedDataloggers = [...state.addedDataloggers]
      const selectedDatalogger = addedDataloggers.find(datalogger => datalogger.uuid === dataloggerId)
      if (selectedDatalogger) {
        // Rimetto l'oggetto datalogger solo se non è già presente nell'array dei datalogger disponibili
        const existingDatalogger = currentDataloggers.find(datalogger => datalogger.uuid === dataloggerId)
        if (!existingDatalogger) {
          currentDataloggers.push(selectedDatalogger)
        }

        state.dataloggers = currentDataloggers
        state.addedDataloggers = addedDataloggers.filter(el => el.uuid !== dataloggerId)
      }
    },
    addDatalogger(state, action) {
      const { dataloggerId } = action.payload
      const currentDataloggers = [...state.dataloggers]
      const addedDataloggers = [...state.addedDataloggers]

      const selectedDatalogger = currentDataloggers.find(datalogger => datalogger.uuid === dataloggerId)

      if (selectedDatalogger) {
        addedDataloggers.push(selectedDatalogger)
        state.dataloggers = currentDataloggers
        state.addedDataloggers = addedDataloggers
      }
    }
  }
})

export const reducer = slice.reducer

export const resetInitState = () => dispatch => {
  dispatch(slice.actions.resetPlant())
}

export const setStep = (step) => dispatch => {
  dispatch(slice.actions.setStep({ step }))
}

export const changeBuildingValue = (value, key) => dispatch => {
  dispatch(slice.actions.setBuildingValue({ value, key }))
}

export const createSmartHome = (smartHome, reset = true, userIsSaving = false) => async dispatch => {
  // const { uuid } = smartHome
  const body = encodeForApi(smartHome)

  dispatch(slice.actions.setLoadData({ loading: true }))

  const { data: response, error } = await api.post({ savedUrl: 'dashboard', path: '/components/plants', body })

  if (error) {
    return { createdPlant: null, error: true }
  }

  const createdPlant = { ...response }
  /* if (!uuid || userIsSaving) {
    await api.post({ savedUrl: 'forceSummaryRefresh', path: `/${createdPlant.uuid}/summary` })
  } */
  if (reset) {
    dispatch(slice.actions.resetPlant())
    dispatch(slice.actions.setLoadData({ loading: false }))
  }
  return { createdPlant, error: false }
}

export const getCurrentSmartHome = (buildingId, firstLoad = false) => async dispatch => {
  if (!buildingId) {
    return
  }

  if (firstLoad) {
    dispatch(slice.actions.setLoadData({ loading: true }))
  }

  const options = {
    path: `/components/plants/${buildingId}`
  }

  const { data: response } = await api.get({ savedUrl: 'dashboard', ...options })
  if (response) {
    const { assets, plant, dataloggers, devices } = response || {}
    const adaptedPlant = decodeFromApi({ assets, plant, dataloggers, devices })
    dispatch(slice.actions.setCurrentPlant(adaptedPlant))
  }
}

// funzione che esegue il toggle del campo maintenance
export const toggleMaintenance = (maintenance, plantId) => async (dispatch) => {
  const { error } = await api.put({ savedUrl: 'plantsList', path: `/${plantId}`, body: { 'metadata.maintenance': maintenance } })
  if (error) {
    return false
  }

  dispatch(slice.actions.toggleMaintenance({ maintenance }))
  return true
}

export const getAggregators = ({ fields = null, skip = 0, limit = 50, filter = null }) => async dispatch => {
  const params = {
    skip,
    limit,
    sortorder: 'DESC'
  }
  if (fields) {
    params.fields = fields
  }

  if (filter) {
    params.filter = filter
  }

  const { data: aggregators } = await api.get({ savedUrl: 'aggregatorsList', params })
  dispatch(slice.actions.setBuildingValue({ value: aggregators, key: 'aggregators' }))
}

export const getDistricts = ({ fields = null, skip = 0, limit = 50, filter = null }) => async dispatch => {
  const params = {
    skip,
    limit,
    sortorder: 'DESC'
  }

  if (fields) {
    params.fields = fields
  }

  if (filter) {
    params.filter = filter
  }

  const { data: districts } = await api.get({ savedUrl: 'districtsList', params })
  dispatch(slice.actions.setBuildingValue({ value: districts, key: 'districts' }))
}

export const getAssetTypes = ({ fields = null, skip = 0, limit = 50, filter = null }) => async dispatch => {
  const params = {
    skip,
    limit,
    sortorder: 'DESC'
  }

  if (fields) {
    params.fields = fields
  }

  if (filter) {
    params.filter = filter
  }

  const { data: assetTypes } = await api.get({ savedUrl: 'assetTypes', params })
  dispatch(slice.actions.setBuildingValue({ value: assetTypes, key: 'assetTypes' }))
}

export const getDataloggers = ({ skip = 0, limit = 50, filter = null, hasNoPlant = null }) => async dispatch => {
  const params = {
    skip,
    limit,
    sortorder: 'DESC',
    include: 'devices'
  }

  if (filter) {
    params.filter = filter
  }

  if (hasNoPlant !== null) {
    params.hasNoPlant = hasNoPlant
  }

  const { data: dataloggers } = await api.get({ savedUrl: 'nodesList', params })
  dispatch(slice.actions.setBuildingValue({ value: dataloggers, key: 'dataloggers' }))
}

export const deleteDatalogger = (dataloggerId) => dispatch => {
  dispatch(slice.actions.removeDatalogger({ dataloggerId }))
}

export const addDatalogger = (dataloggerId) => dispatch => {
  dispatch(slice.actions.addDatalogger({ dataloggerId }))
}
