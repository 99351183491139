import log from '@pelv/frontlog'
import { createContext, useEffect, useReducer } from 'react'
import SplashScreen from '@components/SplashScreen'
import { clientPermissions, clientServices, configuration } from '@/config.js'
import api from '@micmnt/apis'
import { useLocation } from 'react-router-dom'

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
}

const newBasicRole = [
  'enea.account.write'
]

const setSession = (accessToken) => {
  if (accessToken) {
    window.localStorage.setItem('enea-accessToken', accessToken)
  } else {
    window.localStorage.removeItem('enea-accessToken')
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      }
    }
    case 'LOGIN': {
      const { user } = action.payload

      return {
        ...state,
        isAuthenticated: true,
        user
      }
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      }
    }
    case 'REGISTER': {
      const { user } = action.payload

      return {
        ...state,
        isAuthenticated: true,
        user
      }
    }
    case 'UPDATE_USER': {
      const { newEdits } = action.payload

      return {
        ...state,
        user: {
          ...state.user,
          ...newEdits
        }
      }
    }
    case 'UPDATE_USER_IMAGE': {
      const { newUrl } = action.payload

      return {
        ...state,
        user: {
          ...state.user,
          metadata: {
            ...state.user.metadata,
            imageURL: newUrl
          }
        }
      }
    }
    case 'UPDATE_USER_ZONES': {
      const { zones } = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          metadata: {
            ...state.user.metadata,
            zones
          }
        }
      }
    }
    case 'UPDATE_USER_ANALYTICS_FILTERS': {
      const { filters } = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          metadata: {
            ...state.user.metadata,
            analyticsFilters: filters
          }
        }
      }
    }
    case 'UPDATE_USER_FILTERS': {
      const { filters } = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          metadata: {
            ...state.user.metadata,
            userFilters: filters
          }
        }
      }
    }
    case 'UPDATE_REPORT_EMAIL': {
      const { emails } = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          metadata: {
            ...state.user.metadata,
            reportEmails: emails
          }
        }
      }
    }
    default: {
      return { ...state }
    }
  }
}

const AuthContext = createContext({
  ...initialAuthState,
  allUserPermissions: [...clientPermissions, ...clientServices],
  newBasicRole: newBasicRole,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { }
  // register: () => Promise.resolve()
})

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState)

  // Controllo che nell'url sia presente il token SSO
  const { search, pathname } = useLocation()

  const urlSearchParams = new URLSearchParams(search)
  const { token: SSOToken } = Object.fromEntries(urlSearchParams.entries())

  const login = async (email, password) => {
    const { data: response, error } = await api.post({ savedUrl: 'accounts', path: '/authenticate', body: { email, password }, fullResponse: true })

    if (error) {
      await logout()
    }

    log({ text: 'login reponse => ', variable: response.data.data, tag: 'authcontext' })
    const { token, account, roles, groups } = response.data.data
    const thisProjectId = configuration.projectId

    account.role = roles.find(role => role.projectId === thisProjectId) || {}
    account.appPermissions = account?.role?.metadata?.applicationPermissions ? account.role.metadata.applicationPermissions : []
    account.groups = groups || []
    if (!account.metadata.reportEmails) {
      account.metadata.reportEmails = [account.email]
    }
    if (!account.mustConfirmEmailAddress) {
      account.mustConfirmEmailAddress = false
    }

    setSession(token)
    dispatch({
      type: 'LOGIN',
      payload: {
        user: account
      }
    })
  }

  const updateZones = (zones) => {
    dispatch({
      type: 'UPDATE_USER_ZONES',
      payload: {
        zones
      }
    })
  }

  const updateFilters = (customFilters) => {
    dispatch({
      type: 'UPDATE_USER_FILTERS',
      payload: {
        filters: customFilters
      }
    })
  }

  const updateAnalyticsFilters = (customFilters) => {
    dispatch({
      type: 'UPDATE_USER_ANALYTICS_FILTERS',
      payload: {
        filters: customFilters
      }
    })
  }

  const updateReportEmails = (emails) => {
    dispatch({
      type: 'UPDATE_REPORT_EMAIL',
      payload: {
        emails: emails
      }
    })
  }

  const logout = async () => {
    const { error } = await api.post({ savedUrl: 'logout' })

    if (error) {
      return console.error('Error while loggin out, err => ', error)
    }

    setSession(null)
    dispatch({ type: 'LOGOUT' })
  }

  // funzione che aggiorna l'immagine dello user salvato
  const updateLocalUserImage = (newUrl) => {
    log({ text: 'update image, newUrl => ', variable: newUrl, tag: 'authcontext' })
    dispatch({
      type: 'UPDATE_USER_IMAGE',
      payload: {
        newUrl: newUrl || null
      }
    })
  }

  // funzione che aggiorna il nome dello user salvato
  const updateLocalUser = (newEdits) => {
    log({ text: 'update nome, newName => ', variable: newEdits, tag: 'authcontext' })
    dispatch({
      type: 'UPDATE_USER',
      payload: {
        newEdits: newEdits || null
      }
    })
  }

  // funzione che fa il setup delle info utente in seguito all'autenticazione tramite token
  const setupUserWithAuthToken = async (token) => {
    setSession(token)

    const userParams = {
      include: 'roles,groups,aclrules,groupmemberships'
    }

    const { data: response, error } = await api.get({ savedUrl: 'accounts', path: '/self', params: userParams, fullResponse: true })

    if (error) {
      console.error(error)
      return dispatch({
        type: 'INITIALISE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      })
    }

    const account = response.data.data
    const currentProject = configuration.projectId
    account.role = account.includes.roles.find(role => role.projectId === currentProject) || {}
    account.acl = account.includes.aclrules || []
    account.groups = account.includes.groups || []
    account.groupmemberships = account.includes.groupmemberships || []
    // account.frontPermissions = account.role.metadata && account.role.metadata.userPermissions ? JSON.parse(account.role.metadata.userPermissions) : {}
    account.appPermissions = account?.role?.metadata?.applicationPermissions ? account.role.metadata.applicationPermissions : []
    if (!account.metadata.reportEmails) {
      account.metadata.reportEmails = [account.email]
    }
    if (!account.mustConfirmEmailAddress) {
      account.mustConfirmEmailAddress = false
    }

    return { account }
  }

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('enea-accessToken')

        if (accessToken) {
          const { account } = await setupUserWithAuthToken(accessToken)

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user: account
            }
          })
        } else if (SSOToken) {
          // Se sono nella rotta di logout, non tengo conto del token ma devo fare logout
          if (pathname === '/logout') {
            return dispatch({
              type: 'INITIALISE',
              payload: {
                isAuthenticated: false,
                user: null
              }
            })
          }
          // Devo fare la richiesta per avere il token di autenticazione
          const { data: response } = await api.post({
            savedUrl: 'accounts',
            path: '/authenticate',
            body: {
              token: SSOToken
            }
          })

          // Ricavo il token di autenticazione
          const authToken = response.token || null

          if (authToken) {
            const { account } = await setupUserWithAuthToken(authToken)
            dispatch({
              type: 'INITIALISE',
              payload: {
                isAuthenticated: true,
                user: account
              }
            })
          } else {
            dispatch({
              type: 'INITIALISE',
              payload: {
                isAuthenticated: false,
                user: null
              }
            })
          }
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          })
        }
      } catch (e) {
        setSession(null)
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        })
      }
    }

    initialise()
  }, [])

  if (!state.isInitialised) {
    return <SplashScreen />
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        allUserPermissions: [...clientPermissions, ...clientServices],
        newBasicRole: newBasicRole,
        method: 'JWT',
        login,
        logout,
        // register,
        updateFilters,
        updateAnalyticsFilters,
        updateReportEmails,
        updateZones,
        updateLocalUserImage,
        updateLocalUser
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
