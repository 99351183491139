import { useState, useEffect, memo } from 'react'
import {
  NavLink as RouterLink,
  // useHistory,
  useLocation
} from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  ClickAwayListener,
  IconButton,
  Popper,
  Fade,
  Paper,
  Grid,
  List,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  makeStyles,
  useTheme
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  listContainer: {
    minWidth: 180,
    minHeight: 100
  },
  paper: {
    padding: theme.spacing(1)
    // backgroundColor: theme.palette.background.paper,
    // '@supports (backdrop-filter: none)': {
    //   backdropFilter: 'saturate(100%) blur(50px)',
    //   backgroundColor: 'rgba(38, 61, 84, 0.5)'
    // }
  }
}))

const MobileNavItem = ({ section, icon: Icon, ...rest }) => {
  const classes = useStyles()
  const theme = useTheme()
  // const history = useHistory()
  const location = useLocation()
  const [anchorEl, setAnchorEl] = useState(null)
  const [openPopper, setOpenPopper] = useState(false)

  useEffect(() => {
    // resetto i menu
    setOpenPopper(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <>
      <IconButton
        style={{
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
          paddingLeft: theme.spacing(2) - 2,
          paddingRight: theme.spacing(2) - 2
        }}
        color='default'
        aria-label={section.title}
        onClick={(event) => {
          setAnchorEl(event.currentTarget)
          setOpenPopper(true)
        }}
      >
        <Icon style={{ color: location.pathname.includes(section.href) ? theme.palette.primary.main : null }} />
      </IconButton>
      <Popper
        open={openPopper}
        anchorEl={anchorEl}
        placement='top-start'
        transition
        style={{ zIndex: '10000', marginBottom: 10 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              elevation={4}
              onMouseEnter={() => setOpenPopper(true)}
              onMouseLeave={() => setOpenPopper(false)}
              // style={{ padding: theme.spacing(1) }}
              className={classes.paper}
            >
              <ClickAwayListener onClickAway={(event) => setOpenPopper(false)}>
                <Grid container spacing={1} className={classes.listContainer}>
                  <Grid item xs={12}>
                    <List
                      component='nav'
                      aria-labelledby={section.title + '-nav'}
                      subheader={
                        <ListSubheader component='div' id={section.title + '-nav-title'}>{section.title}</ListSubheader>
                      }
                      style={{ minWidth: 230 }}
                    >
                      {section.items.map((item, index) => {
                        const { UrlIcon, title, href } = item
                        return (
                          <ListItem key={index} button component={RouterLink} to={href}>
                            <ListItemAvatar>
                              <Avatar style={{ backgroundColor: location.pathname.includes(href) ? theme.palette.primary.main : null }}>
                                <UrlIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={title} />
                          </ListItem>
                        )
                      })}
                    </List>
                  </Grid>
                </Grid>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  )
}

MobileNavItem.propTypes = {
  section: PropTypes.object,
  icon: PropTypes.elementType
}

export default memo(MobileNavItem)
