import { combineReducers } from '@reduxjs/toolkit'
// PLantTypes reducers
import { reducer as smartHomeReducer } from '@slices/smarthome/plant'
import { reducer as smartHomeViewReducer } from '@slices/smarthome/plantView'
import { reducer as smartBuildingViewReducer } from '@slices/smartbuilding/plantView'

import { reducer as mapsReducer } from '@slices/maps'
import { reducer as plantsReducer } from '@slices/plants'

const rootReducer = combineReducers({
  plants: plantsReducer,
  smarthomePlant: smartHomeReducer,
  smarthomeView: smartHomeViewReducer,
  smartbuildingView: smartBuildingViewReducer,
  maps: mapsReducer
})

export default rootReducer
