import { memo } from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { create } from 'jss'
import rtl from 'jss-rtl'
import MomentUtils from '@date-io/moment'
import { SnackbarProvider } from 'notistack'
import { jssPreset, StylesProvider, ThemeProvider, Slide, CssBaseline } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import GlobalStyles from '@components/GlobalStyles'
import ScrollReset from '@components/ScrollReset'
import { AuthProvider } from '@contexts/JWTAuthContext'
import useSettings from '@hooks/useSettings'
import { createTheme } from '@theme'
import routes, { renderRoutes } from '@/routes'
// import { getBuildDate } from '@utils/getBuildDate'
import 'moment/locale/it'
import { ChartsProvider } from '@contexts/ChartsContext'
import api from '@micmnt/apis'
import { configuration } from './config'
import { apiConfig } from '@utils/apiResourcesConfig'

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
const history = createBrowserHistory()

// Inizializzazione del servizio di chiamate API
api.init({
  baseUrl: configuration.apiUrl,
  savedUrls: apiConfig,
  jwtTokenName: 'enea-accessToken',
  placeholders: { projectId: `/projects/${configuration.projectId}` }
})

// utility to know the build date printed in dev console
// console.log('Build date: ', getBuildDate())

const App = () => {
  const { settings } = useSettings()

  // creo il tema
  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  })

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={MomentUtils} locale='it'>
          <SnackbarProvider
            maxSnack={5}
            TransitionComponent={Slide}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            autoHideDuration={4000}
          >
            <Router history={history}>
              <ChartsProvider>
                <AuthProvider>
                  <GlobalStyles />
                  <ScrollReset />
                  {renderRoutes(routes)}
                </AuthProvider>
              </ChartsProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  )
}

export default memo(App)
