import { useState, useEffect, memo, Fragment } from 'react'
import { useLocation, Link as RouterLink } from 'react-router-dom'
import { canIAccessThisUrl } from '@hooks/usePermissions'
import {
  AppBar,
  Toolbar,
  Divider,
  Drawer,
  useMediaQuery,
  makeStyles,
  Box
} from '@material-ui/core'
import Logo from '@components/Logo'
import useAuth from '@hooks/useAuth'
import NavItem from './NavItem'
import MobileNavItem from './MobileNavItem'
import Settings from './Settings'

import {
  HomeWorkTwoTone as PlantsIcon,
  BusinessTwoTone as PlantIcon,
  AppsTwoTone as OverviewIcon,
  SettingsTwoTone as SettingsIcon,
  ChromeReaderModeTwoTone as ManagmentIcon,
  AssessmentTwoTone as AnalyticsIcon,
  PlaceTwoTone as MapIcon,
  DeveloperBoardTwoTone as DeviceIcon,
  AssignmentIndTwoTone as RolesIcon,
  AccountCircleTwoTone as UserIcon,
  PeopleTwoTone as UsersIcon,
  SettingsTwoTone as AggregatorIcon,
  Notifications as AlarmsIcon
} from '@material-ui/icons'
import clsx from 'clsx'

// Rotta della visualizzazione singolo edificio per un citizen
const citizenRoute = '/app/view/plant'
const manageBuildingRoute = '/app/management/plants'
const manageAggregatorsRoute = '/app/management/aggregators'

export const routeSections = [
  {
    title: 'Panoramica',
    UrlIcon: OverviewIcon,
    href: '/app/overview',
    showOnMenu: true,
    items: [
      {
        title: 'Mappa',
        href: '/app/overview/maps',
        showOnMenu: true,
        UrlIcon: MapIcon,
        shortView: null
      },
      {
        title: 'Aggregatore',
        href: '/app/overview/analytics',
        showOnMenu: true,
        UrlIcon: AnalyticsIcon,
        shortView: null
      },
      {
        title: 'Anomalie',
        href: '/app/overview/anomalies',
        showOnMenu: true,
        UrlIcon: AlarmsIcon,
        shortView: null
      },
      {
        title: 'Edifici',
        href: '/app/management/plants',
        showOnMenu: true,
        UrlIcon: PlantsIcon,
        shortView: null
      },
      {
        title: 'Edificio',
        href: '/app/view/plant',
        showOnMenu: false,
        UrlIcon: PlantIcon,
        shortView: null
      }
    ]
  },
  {
    title: 'Gestione',
    UrlIcon: ManagmentIcon,
    href: '/app/management',
    showOnMenu: true,
    items: [
      {
        title: 'Datalogger',
        href: '/app/management/devices',
        showOnMenu: true,
        UrlIcon: DeviceIcon,
        shortView: null
      },
      {
        title: 'Impostazioni',
        href: '/app/management/aggregators',
        showOnMenu: true,
        UrlIcon: AggregatorIcon,
        shortView: null
      },
      {
        title: 'Utenti',
        href: '/app/management/users',
        showOnMenu: true,
        UrlIcon: UsersIcon,
        shortView: null
      },
      {
        title: 'Ruoli',
        href: '/app/management/roles',
        showOnMenu: true,
        UrlIcon: RolesIcon,
        shortView: null
      }
    ]
  },
  {
    title: 'Impostazioni',
    UrlIcon: SettingsIcon,
    href: '/app/settings',
    showOnMenu: false,
    items: [
      {
        title: 'Account',
        href: '/app/settings/account',
        showOnMenu: false,
        UrlIcon: UserIcon,
        shortView: null
      }
    ]
  }
]

const useStyles = makeStyles((theme) => ({
  baseDrawer: {
    height: '100%',
    paddingTop: theme.spacing(2),
    textAlign: 'center',
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  baseDrawerPaper: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.main
  },
  desktopDefaultDrawer: {
    width: 53
  },
  desktopDefaultDrawerPaper: {
    width: 53
  },
  desktopExtendedDrawer: {
    width: 220
  },
  desktopExtendedPaperDrawer: {
    overflowX: 'hidden',
    width: 220
  },
  mobileNav: {
    top: 'auto',
    left: '3%',
    bottom: '1.5%',
    width: '94%',
    borderRadius: 40,
    zIndex: 99999
  },
  mobileNavPaper: {
    backgroundColor: theme.palette.background.dark,
    '@supports (backdrop-filter: none) or (-webkit-backdrop-filter: none)': {
      '-webkit-backdrop-filter': 'saturate(98%) blur(14px)',
      backdropFilter: 'saturate(98%) blur(14px)',
      backgroundColor: 'rgba(24, 38, 56, 0.3)',
      borderRadius: 40
    }
  },
  grow: {
    flexGrow: 1
  },
  divider: {
    marginTop: theme.spacing(1) - 4,
    marginBottom: theme.spacing(1) - 4
  },
  hiddenDivider: {
    marginTop: theme.spacing(1) - 4,
    marginBottom: theme.spacing(1) - 4,
    backgroundColor: 'transparent'
  },
  extendedLogoOnButton: {
    width: 180,
    height: 'auto'
  },
  extendedLogoContainer: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(1)
  },
  logoOnButton: {
    width: 'auto',
    height: 28
  }
}))

const NavBar = ({ extended = false }) => {
  const classes = useStyles()
  const location = useLocation()
  const { user } = useAuth()
  const [navSections, setNavSections] = useState([])
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  // Variabile che determina se un utente può solo visualizzare un edificio
  const isBuildingViewer = user?.role?.metadata?.applicationPermissions?.filter(el => el.startsWith('enea.'))?.length < 3 &&
    (user?.role?.metadata?.applicationPermissions?.filter(el => el.startsWith('enea.'))?.[0] === 'enea.buildings.section' ||
      user?.role?.metadata?.applicationPermissions?.filter(el => el.startsWith('enea.'))?.[0] === 'enea.buildings.read')

  // Variabile che determina se un utente può solo visualizzare un aggregatore
  const isAggregatorViewer = !user?.role?.metadata?.applicationPermissions
    ?.filter(el => el.startsWith('enea.')).includes('enea.aggregators.write')

  // mi sistemo i dati
  useEffect(() => {
    // nav
    let temp = []
    const final = []
    // mi setto la variabile per la nav
    routeSections.forEach(section => {
      const thisGroup = {
        title: section.title,
        href: section.href,
        UrlIcon: section.UrlIcon,
        showOnMenu: section.showOnMenu
      }
      temp = section.items.filter(route => {
        let canBeShowed = route.showOnMenu
        // controllo se posso accedere all'url
        const thisAnswer = canIAccessThisUrl(user?.appPermissions || [], route.href, user?.groups || [])
        // Se posso visualizzare la mia casa
        if (route.href === citizenRoute && isBuildingViewer) {
          canBeShowed = true
        }
        if (route.href === manageBuildingRoute && isBuildingViewer) {
          canBeShowed = false
        }
        if (route.href === manageAggregatorsRoute && isAggregatorViewer) {
          canBeShowed = false
        }
        return thisAnswer.answer && canBeShowed
      })
      if (temp.length > 0) {
        final.push({ ...thisGroup, items: temp })
        temp = []
      }
    })
    setNavSections(final)
  }, [location.pathname])

  return !isSmall
    ? (
      <Drawer
        elevation={4}
        variant='permanent'
        className={extended ? clsx(classes.baseDrawer, classes.desktopExtendedDrawer) : clsx(classes.baseDrawer, classes.desktopDefaultDrawer)}
        classes={{ paper: extended ? clsx(classes.baseDrawerPaper, classes.desktopExtendedPaperDrawer) : clsx(classes.baseDrawerPaper, classes.desktopDefaultDrawerPaper) }}
      > {
          extended
            ? (
              <Box display='flex' justifyContent='center' alignItems='center'>
                <Box width='200px' pt={2} pb={1} display='flex' alignItems='center' justifyContent='center' className={classes.extendedLogoContainer}>
                  <RouterLink to='/app'>
                    <Logo secondary text className={classes.extendedLogoOnButton} />
                  </RouterLink>
                </Box>
              </Box>
            )
            : isBuildingViewer
              ? (
                <Box display='flex' justifyContent='center' alignItems='center'>
                  <Box className={classes.extendedLogoContainer}>
                    <RouterLink to='/app'>
                      <Logo secondary short className={classes.logoOnButton} />
                    </RouterLink>
                  </Box>
                </Box>
              )
              : (
                <RouterLink to='/app'>
                  <Logo short logocolor='white' className={classes.logoOnButton} />
                </RouterLink>
              )
        }
        <Divider className={classes.hiddenDivider} />
        {navSections.map((group, groupIndex) => {
          return (
            <Fragment key={groupIndex}>
              <Divider className={classes.divider} />
              {group.items && group.items.map((section, index) => {
                if (!section.showOnMenu) {
                  return ''
                }

                return (
                  <NavItem extended={extended} key={index} icon={section.UrlIcon} shortView={section.shortView} section={section} />
                )
              })}
            </Fragment>
          )
        })}
        <Divider className={classes.divider} />
        <Settings extended={extended} />
      </Drawer>
    )
    : (
      <AppBar position='fixed' color='transparent' className={classes.mobileNav}>
        <Toolbar className={classes.mobileNavPaper}>
          {navSections.map((section, index) => {
            if (!section.showOnMenu) {
              return ''
            }

            return (
              <MobileNavItem key={index} icon={section.UrlIcon} section={section} />
            )
          })}
          <div className={classes.grow} />
          <Settings />
        </Toolbar>
      </AppBar>
    )
}

export default memo(NavBar)
