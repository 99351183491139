import { useState, useEffect } from 'react'
import { clientPermissions, clientServices } from '@/config.js'
const accountPath = '/app/settings/account'
const welcomePath = '/app/welcome'
const reportsPath = '/app/overview/reports'
const mapsPath = '/app/overview/maps'
const citizenPath = '/app/view/plant'

export const canIAccessThisUrl = (appPermissions, pathname, groups) => {
  let objToReturn = {
    answer: true,
    redirectTo: null,
    thisAcceptedUrls: [],
    thisDefaultsUrls: []
  }

  // mi prendo gli url accettati per tutti i suoi permessi
  if (!pathname.includes(accountPath) && !pathname.includes(welcomePath)) {
    appPermissions && appPermissions.forEach(userAppPerm => {
      const thisPermIndex = clientPermissions.findIndex(appPerm => appPerm.value === userAppPerm)
      if (thisPermIndex > -1) {
        for (const route of clientPermissions[thisPermIndex].acceptedRoutes) {
          if (!objToReturn.thisAcceptedUrls.includes(route)) {
            objToReturn.thisAcceptedUrls.push(route)
          }
        }
        objToReturn.thisDefaultsUrls.push(clientPermissions[thisPermIndex].defaultRoute)
      }

      const thisServiceIndex = clientServices.findIndex(appPerm => appPerm.value === userAppPerm)
      if (thisServiceIndex > -1) {
        for (const route of clientServices[thisServiceIndex].acceptedRoutes) {
          if (!objToReturn.thisAcceptedUrls.includes(route)) {
            objToReturn.thisAcceptedUrls.push(route)
          }
        }
        objToReturn.thisDefaultsUrls.push(clientServices[thisServiceIndex].defaultRoute)
      }
    })

    // controllo che l'url attuale sia incluso negli url accettati per l'utente
    if (!objToReturn.thisAcceptedUrls.find(goodUrl => pathname.includes(goodUrl))) {
      // l'url attuale non è incluso in quelli che può visualizzare, faccio redirect
      objToReturn = {
        ...objToReturn,
        answer: false,
        redirectTo: objToReturn.thisDefaultsUrls.length > 0
          ? objToReturn.thisDefaultsUrls.includes(mapsPath)
            ? mapsPath
            : objToReturn.thisDefaultsUrls[0]
          : pathname.includes(accountPath)
            ? accountPath
            : welcomePath
      }
    } else if (pathname.includes(reportsPath)) {
      // sta accedendo ad i report di board ed ha il permesso, controllo i gruppi
      if (groups && groups.length > 0) {
        // ha gruppi non può accedere
        objToReturn = {
          ...objToReturn,
          answer: false,
          redirectTo: accountPath
        }
      }
    }
  }
  // console.log('objToReturn => ', objToReturn, 'usePermissions')
  return objToReturn
}

// Hook che prende in ingresso una queryString ?key=value&key2=value2... e restituisce un oggetto {key: value, key2: value2, ...}
export const useThisToAccess = (user, pathname) => {
  //  mi preparo la variabile da restituire
  const [canIAccessUrl, setCanIAccessUrl] = useState({
    answer: true,
    redirectTo: null,
    thisAcceptedUrls: [],
    thisDefaultsUrls: []
  })

  const canViewSingleBuilding = user?.metadata?.acl?.plants?.length === 1

  useEffect(() => {
    // controllo il path girato, se è quello di account lascio passare
    if (pathname && (!pathname.includes(accountPath) && !pathname.includes(welcomePath) && !pathname.includes(citizenPath))) {
      // controllo se ci sono i permessi nel config
      if (clientPermissions && clientPermissions.length > 0) {
        // controllo se ha i permessi necessari per accedere all'url
        if (user && user.appPermissions) {
          if(canViewSingleBuilding) {
            setCanIAccessUrl(prev => {
              return {
                ...prev,
                answer: true,
                redirectTo: citizenPath
              }
            })

          }
          // controllo che l'utente non abbia tutti i permessi, in quel caso lascio passare perché è super admin
          if (user.appPermissions.length !== clientPermissions.length) {
            setCanIAccessUrl(() => {
              const newObj = canIAccessThisUrl(user.appPermissions, pathname, user.groups)
              return newObj
            })
          }
        } else {
          // mancano delle info redirigo verso account
          setCanIAccessUrl(prev => {
            return {
              ...prev,
              answer: true,
              redirectTo: accountPath
            }
          })
        }
      }
    } else {
      const redirectPath = pathname.includes(accountPath) ? accountPath : pathname.includes(citizenPath) ? citizenPath : welcomePath
      setCanIAccessUrl(prev => {
        return {
          ...prev,
          answer: true,
          redirectTo: redirectPath
        }
      })
    }
  }, [user, pathname])

  return canIAccessUrl
}
