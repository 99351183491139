import { Suspense, Fragment, lazy } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import SmartLayout from '@layouts/SmartLayout'
import MainLayout from '@layouts/MainLayout'
import LoadingScreen from '@components/LoadingScreen'
import AuthGuard from '@components/AuthGuard'
import GuestGuard from '@components/GuestGuard'
import ShareLayout from '@layouts/ShareLayout'
import VerticalReportLayout from '@layouts/VerticalReportLayout'
import Logout from '@views/auth/Logout'
import { configuration } from '@/config'

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment
        const Layout = route.layout || Fragment
        const Component = route.component

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        )
      })}
    </Switch>
  </Suspense>
)

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('@views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: configuration.environment === 'production' ? () => <Redirect to={`/app${window.location.search}`} /> : lazy(() => import('@views/auth/LoginView'))
    // component: lazy(() => import('/src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/logout',
    component: configuration.environment === 'production' ? () => <Logout /> : lazy(() => import('@views/auth/LoginView'))
    // component: () => <Redirect to={`/app${window.location.search}`} />
    // component: lazy(() => import('/src/views/auth/LoginView'))
  },
  {
    exact: true,
    // guard: GuestGuard,
    path: '/account/activate/:token/:email',
    component: lazy(() => import('@views/auth/ConfirmEmailView'))
  },
  {
    exact: true,
    // guard: GuestGuard,
    path: '/account/newemail/:token/:email',
    component: lazy(() => import('@views/auth/ConfirmNewEmailView'))
  },
  {
    exact: true,
    // guard: GuestGuard,
    path: '/password/reset/:token/:email',
    component: lazy(() => import('@views/auth/ResetPasswordView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('@views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/shared/:token',
    layout: ShareLayout,
    component: lazy(() => import('@views/share/ShareView'))
  },
  {
    exact: true,
    path: '/report/smarthome',
    layout: VerticalReportLayout,
    component: lazy(() => import('@views/reports/Smarthome'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: SmartLayout,
    routes: [
      {
        exact: true,
        path: '/app/overview/analytics',
        component: lazy(() => import('@views/overview/AnalyticsView'))
      },
      {
        exact: true,
        path: '/app/overview/maps',
        component: lazy(() => import('@views/overview/MapsView'))
      },
      {
        exact: true,
        path: '/app/overview/anomalies',
        component: lazy(() => import('@views/overview/AnomaliesView'))
      },
      {
        exact: true,
        path: '/app/settings/account',
        component: lazy(() => import('@views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/welcome',
        component: lazy(() => import('@views/welcome/WelcomeView'))
      },
      {
        exact: true,
        path: '/app/management/users',
        component: lazy(() => import('@views/users/usersListView'))
      },
      {
        exact: true,
        path: '/app/management/roles',
        component: lazy(() => import('@views/users/rolesView'))
      },
      {
        exact: true,
        path: '/app/management/devices',
        component: lazy(() => import('@views/devices/DevicesListView'))
      },
      {
        exact: true,
        path: '/app/management/plants',
        component: lazy(() => import('@views/systems/SystemsListView'))
      },
      {
        exact: true,
        path: '/app/management/aggregators',
        component: lazy(() => import('@views/aggregators/AggregatorsListView'))
      },
      {
        exact: true,
        path: '/app/view/plant',
        component: lazy(() => import('@views/plant/PlantView'))
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to='/app/overview/maps' />
        // component: () => <Redirect to='/app/settings/account' />
      },
      {
        component: () => <Redirect to='/404' />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        component: () => <Redirect to={`/app${window.location.search}`} />
      }
    ]
  }
]

export default routes
