import { createContext, useCallback, useReducer } from 'react'

const initialTopBarState = {
  name: '-',
  peakPower: '-',
  startDate: '-',
  plantType: '',
  contractDuration: '-',
  endDate: '-',
  uuid: null,
  maintenance: false,
  documents: []
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_INFOS': {
      const {
        name,
        peakPower,
        startDate,
        plantType,
        contractDuration,
        endDate,
        uuid,
        maintenance,
        documents
      } = action.payload

      return {
        ...state,
        name,
        peakPower,
        startDate,
        plantType,
        contractDuration,
        endDate,
        uuid,
        maintenance,
        documents
      }
    }
    case 'RESET_INFOS': {
      return { ...initialTopBarState }
    }
    default: {
      return { ...state }
    }
  }
}

const TopBarContext = createContext({
  ...initialTopBarState,
  setInfos: () => Promise.resolve(),
  resetInfos: () => Promise.resolve()
})

export const TopBarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialTopBarState)

  const setInfos = useCallback((plant) => {
    if (plant) {
      const {
        name,
        peakPower,
        startDate,
        plantType,
        contractDuration,
        endDate,
        uuid,
        maintenance,
        documents
      } = plant

      dispatch({
        type: 'SET_INFOS',
        payload: {
          name,
          peakPower,
          startDate,
          contractDuration,
          plantType,
          endDate,
          uuid,
          maintenance,
          documents
        }
      })
    }
  }, [])

  const resetInfos = useCallback(() => {
    dispatch({ type: 'RESET_INFOS' })
  }, [])

  return (
    <TopBarContext.Provider
      value={{
        ...state,
        setInfos,
        resetInfos
      }}
    >
      {children}
    </TopBarContext.Provider>
  )
}

export default TopBarContext
