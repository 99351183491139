import { memo } from 'react'

const logo = '/static/logo.svg'
const secondaryLogo = '/static/logo_secondario.png'
const shortSecondaryLogo = '/static/marchio_secondario.png'
const secondaryTextLogo = '/static/logo_text_version.png'
const whiteLogo = '/static/logo_bianco.svg'
const blackLogo = '/static/marchio_nero.svg'
const shortLogo = '/static/marchio.svg'
const shortWhiteLogo = '/static/marchio_bianco.svg'
const shortBlackLogo = '/static/marchio_nero.svg'

const Logo = ({ short = false, logocolor = 'default', secondary = false, text = false, ...rest }) => {
  let logoUrl = logo
  if (secondary) {
    if (short) {
      logoUrl = shortSecondaryLogo
    } else if (text) {
      logoUrl = secondaryTextLogo
    } else {
      logoUrl = secondaryLogo
    }
  } else {
    if (short) {
      logoUrl = logocolor === 'white' ? shortWhiteLogo : logocolor === 'black' ? shortBlackLogo : shortLogo
    } else {
      logoUrl = logocolor === 'white' ? whiteLogo : logocolor === 'black' ? blackLogo : logo
    }
  }

  return (
    <img
      alt='Logo'
      height='auto'
      width='auto'
      src={logoUrl}
      {...rest}
    />
  )
}

export default memo(Logo)
