import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'nprogress/nprogress.css'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { enableES5 } from 'immer'
import * as Sentry from '@sentry/browser'
import store from '@/store'
import { SettingsProvider } from '@contexts/SettingsContext'
import App from '@/App'
import { configuration } from './config'
import { changeLogConfig } from '@pelv/frontlog'

enableES5()

if (configuration.sentry.environment === 'production') {
  Sentry.init({ dsn: configuration.sentry.dsn, environment: configuration.sentry.environment })
}

if (configuration.environment === 'production') {
  changeLogConfig({
    sendLogs: true
  })
}

render(
  <Provider store={store}>
    <SettingsProvider>
      <App />
    </SettingsProvider>
  </Provider>,
  document.getElementById('root')
)