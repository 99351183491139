import { useState, useEffect, memo } from 'react'
import {
  useLocation
} from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Box,
  Fab,
  SvgIcon,
  Tooltip
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { routeSections } from '../NavBar'
import PlantSettings from './PlantSettings'
import { basicColors } from '@theme'
import useTopBarInfo from '@hooks/useTopBarInfo'
import useAuth from '@hooks/useAuth'

import {
  BusinessTwoTone as PlantIcon,
  ReportProblemTwoTone as MaintenanceIcon
} from '@material-ui/icons'

const TopBar = ({ className, ...rest }) => {
  const location = useLocation()
  const { user } = useAuth()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [key, setKey] = useState(null)
  const [filteredBreadcrumbs, setFilteredBreadcrumbs] = useState([])
  const {
    name,
    uuid,
    maintenance,
    documents
  } = useTopBarInfo()

  // mi sistemo i dati
  useEffect(() => {
    // breadcrumbs
    const allRoute = []
    routeSections.forEach(section => {
      allRoute.push({
        title: section.title,
        UrlIcon: section.UrlIcon,
        href: section.href,
        showOnMenu: section.showOnMenu
      })
      allRoute.push(...section.items)
    })
    const breadTemp = allRoute.filter(route => location.pathname.includes(route.href))
    if (breadTemp.length > 0 && breadTemp[0].href === '/app/view') {
      breadTemp.push({
        title: name,
        href: '/app/view/plant',
        showOnMenu: false,
        UrlIcon: PlantIcon
      })
    }
    setFilteredBreadcrumbs(breadTemp)
  }, [location.pathname, name])

  // effect per controllare la conferma email
  useEffect(() => {
    if (user.mustConfirmEmailAddress === true) {
      if (!key) {
        const thisKey = enqueueSnackbar('Per favore, conferma la tua nuova email.', {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          preventDuplicate: true,
          persist: true
        })
        setKey(thisKey)
      }
    } else {
      if (key) {
        closeSnackbar(key)
        setKey(null)
      }
    }
    // chiudo la snackbar all'unmount
    return () => {
      if (key) {
        closeSnackbar(key)
      }
    }
  }, [user.mustConfirmEmailAddress, key])

  if (filteredBreadcrumbs.length > 0 && filteredBreadcrumbs[filteredBreadcrumbs.length - 1].href !== '/app/view/plant') {
    return <></>
  }

  return (
    <Box display='flex' flexDirection='row' alignItems='center' style={{ position: 'fixed', top: 8, right: 8 }}>
      {filteredBreadcrumbs.length > 0 && filteredBreadcrumbs[filteredBreadcrumbs.length - 1].href === '/app/view/plant' && name !== '-' && (
        <>
          {maintenance && (
            <Tooltip title='Impianto in manutenzione'>
              <>
                <Fab
                  size='small'
                  disabled
                  aria-label='impostazioni impianto'
                  style={{ backgroundColor: basicColors.background, marginRight: 6 }}
                >
                  <SvgIcon size='small'>
                    <MaintenanceIcon style={{ color: basicColors.darkYellow }} />
                  </SvgIcon>
                </Fab>
              </>
            </Tooltip>
          )}
          <PlantSettings plantId={uuid} maintenance={maintenance} documents={documents} />
        </>
      )}
    </Box>
  )
}

TopBar.propTypes = {
  className: PropTypes.string
}

export default memo(TopBar)
