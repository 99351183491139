import { useState, useRef } from 'react'
import {
  Fab,
  IconButton,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core'
import { DownloadCloud as DownloadIcon, Eye as ViewIcon, Settings as SettingsIcon } from 'react-feather'
import { basicColors } from '@theme'
import PDFViewerDrawer from '@components/PDFViewerDrawer'

const useStyles = makeStyles((theme) => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  popover: {
    width: 370,
    padding: theme.spacing(2)
  },
  downloadButton: {
    color: basicColors.darkGreen,
    margin: theme.spacing(2)
  },
  viewFileButton: {
    color: theme.palette.primary.main
  },
  list: {
    maxHeight: 400,
    overflowY: 'auto'
  },
  divider: {
    margin: theme.spacing(2, -2)
  },
  settingsItem: {
    marginLeft: theme.spacing(1),
    width: '100%',
    justifyContent: 'space-between'
  }
}))

const PlantSettings = ({ maintenance, plantId, documents }) => {
  const classes = useStyles()

  const ref = useRef(null)

  const [isOpen, setOpen] = useState(false)
  // variabile che mostra o nasconde il drawer per la visualizzazione del PDF
  const [showPDFViewer, setShowPDFViewer] = useState(false)
  // variabile che contiene nome e url del file da visualizzare
  const [selectedFile, setSelectedFile] = useState({ name: null, url: null })

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <PDFViewerDrawer
        file={selectedFile.url}
        title={selectedFile.name}
        open={showPDFViewer}
        onClose={() => {
          setShowPDFViewer(false)
          setSelectedFile({ name: null, url: null })
        }}
      />
      <Tooltip title='Impostazioni Impianto'>
        <Fab
          onClick={handleOpen}
          ref={ref}
          size='small'
          aria-label='impostazioni impianto'
          style={{ backgroundColor: basicColors.background }}
        >
          <SvgIcon size='small'>
            <SettingsIcon color={basicColors.whiteText} />
          </SvgIcon>
        </Fab>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography
          variant='h4'
          color='textPrimary'
        >
          Impostazioni Impianto
        </Typography>
        {documents && documents.length > 0
          ? (
            <>
              <Divider className={classes.divider} />
              <Typography variant='h4' color='textPrimary'>Documenti impianto</Typography>
              <List dense className={classes.list}>
                {documents.map((doc, docIndex) => (
                  <ListItem key={doc.url}>
                    <Tooltip title={doc.name || 'Documento'}>
                      <ListItemText
                        primaryTypographyProps={{ noWrap: true, style: { width: 220 } }}
                        primary={`${docIndex + 1}. ${doc.name || 'Documento'}`}
                      />
                    </Tooltip>
                    <ListItemSecondaryAction>
                      <Tooltip title='Visualizza Documento'>
                        <span>
                          <IconButton
                            size='small'
                            edge='end'
                            className={classes.viewFileButton}
                            disabled={!(doc.url && doc.url !== '')}
                            onClick={() => {
                              setSelectedFile({ name: doc.name, url: doc.url })
                              setShowPDFViewer(true)
                            }}
                          >
                            <ViewIcon fontSize={14} />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title='Scarica Documento'>
                        <span>
                          <IconButton
                            size='small'
                            edge='end'
                            className={classes.downloadButton}
                            disabled={!(doc.url && doc.url !== '')}
                            onClick={(e) => {
                              e.stopPropagation()
                              if (doc.url && doc.url !== '') {
                                const a = document.createElement('a')
                                document.body.appendChild(a)
                                a.style = 'display: none'
                                a.href = doc.url
                                a.click()
                                window.URL.revokeObjectURL(doc.url)
                              }
                            }}
                          >
                            <DownloadIcon fontSize={14} />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </>)
          : null}
      </Popover>
    </>
  )
}

export default PlantSettings
