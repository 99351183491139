import { Redirect, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import useAuth from '@hooks/useAuth'
import { useThisToAccess } from '@hooks/usePermissions'
import log from '@pelv/frontlog'
import { configuration } from '@/config'

const AuthGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth()
  const location = useLocation()

  const { token: SSOToken } = location.search

  
  const { answer, redirectTo } = useThisToAccess(user, location.pathname)
  if (!answer) {
    log([
      { text: 'location => ', variable: location, tag: 'guard' },
      { text: 'access to url => ', variable: answer, tag: 'guard' },
      { text: 'redirectTo => ', variable: redirectTo, tag: 'guard' }
    ])
  }

  if (!isAuthenticated) {
    if (!SSOToken) {
      window.localStorage.removeItem('enea-accessToken')
      window.location.assign(`${configuration.authPlatformLogin}`)
      return null
    }
  }

  // controllo se può visualizzare l'url per i permessi
  if (!answer && redirectTo) {
    return <Redirect to={redirectTo} />
  }

  return (
    <>
      {children}
    </>
  )
}

AuthGuard.propTypes = {
  children: PropTypes.node
}

export default AuthGuard
