// Funzione che prende in ingresso gli assets e gli assetTypes di una smart home e ritorna true o false in base alla presenza o meno dell'asset fotovoltaico
const homeHasAssetType = (assets = [], assetTypes = [], assetTypeName = '') => {
  let response = false
  if (assetTypeName) {
    assets.forEach(asset => {
      const currentAssetType = assetTypes.find(assetType => assetType.uuid === asset.assetTypeId)
      if (currentAssetType?.name === assetTypeName) {
        response = true
      }
    })
  }

  return response
}
/*
 * Funzione che prende in ingresso l'oggetto di risposta del backend e restituisce un oggetto
 * contenente gli oggetti nel formato utile al frontend per consentire le interazioni con l'interfaccia
 *
 * Input: {
 *    orientations: [],
 *    plant: {},
 *    devices: [],
 *    dataloggers: []
 *  }
 *
 * Output: {
 *    plant: {}
 *  }
 */

export const decodeFromApi = (apiResponse) => {
  // Singoli elementi che serviranno per assemblare l'oggetto ritornato per il frontend
  const { plant, dataloggers, assets, devices, assetTypes } = apiResponse || {}
  // Estrapolo le informazioni dell'oggetto 'plant' per preparare i dati per lo store di redux
  const { name, uuid, metadata } = plant || {}

  // Verifico la presenza di un asset fotovoltaico
  const hasPhotovoltaic = homeHasAssetType(assets, assetTypes, 'Fotovoltaico')
  // Verifico la presenza del meter di scambio
  const hasExchangeMeter = homeHasAssetType(assets, assetTypes, 'Contatore di scambio')
  // Verifico la presenza delle batterie
  const hasBatteries = homeHasAssetType(assets, assetTypes, 'Batteria')
  // Aggiungo ai dataloggers ricevuti l'informazione dei devices associati ad essi
  const addedDataloggers = dataloggers.map(datalogger => {
    // Prendo tutti i devices con nodeId uguale all'uuid del datalogger
    const dataloggerDevices = devices.filter(device => device.nodeId === datalogger.uuid)
    return {
      ...datalogger,
      includes: {
        devices: dataloggerDevices
      }
    }
  })

  // Nei devices aggiungo il nome dell'asset associato al singolo device
  const newDevices = devices.map(device => {
    const associatedAsset = assets.find(asset => asset.metadata?.deviceId === device.uuid)
    const currentDevice = associatedAsset ? { ...device, assetName: associatedAsset.name } : device
    return currentDevice
  })

  // Se non è stato impostato un indirizzo sulla mappa, lo setto a stringa vuota per la visualizzazione
  const address = (metadata.address?.inputValue === '' || metadata.address?.coordinates?.length === 0) ? '' : { ...metadata.address }

  const { person_number: personNumber, time_person_number: presence, contract } = metadata.tipologia_utenza || {}

  // Ricreo l'oggetto formattato per la visualizzazione sulla piattaforma
  const contractTypology = {
    tenants: personNumber || 0,
    contractType: contract?.type || '',
    energyMarket: contract?.market || '',
    contractPower: contract?.power || 0,
    priceType: contract?.price || '',
    isGreenEnergy: `${contract?.green}` || 'false',
    tenantsPresence: {
      morning: presence?.['8-13'] || 0,
      afternoon: presence?.['13-19'] || 0,
      evening: presence?.['19-24'] || 0,
      night: presence?.['24-8'] || 0
    }
  }

  // Rimuovo ciò che non mi serve dai metadata
  delete metadata.tipologia_utenza
  delete metadata.weather

  const resultObject = {
    plant: {
      ...metadata,
      ...contractTypology,
      address,
      name,
      uuid,
      addedDataloggers,
      hasPhotovoltaic,
      hasExchangeMeter,
      hasBatteries,
      addedAssets: assets,
      devices: newDevices,
      assetTypes,
      assets
    }
  }

  return resultObject
}

/*
  * Funzione che prende in ingresso l'oggetto 'plant' presente nello store e restituisce un oggetto
  * che potrà essere passato come body alla chiamata POST al backend
  *
  * Input: {
      name: '',
      uuid: '', // solo in caso si tratti di una modifica
      addedDataloggers: [],
      addedAssets: [],
      address: {},
      tenants: 0,
      surface: 0,
      plantType: '',
      contractType: '',
      energyMarket: '',
      contractPower: 0,
      priceType: '',
      userEmail: '',
      isGreenEnergy: false,
      tenantsPresence: {
        morning: 0,
        afternoon: 0,
        evening: 0,
        night: 0
      }
  * }
  *
  * Output: {
  *    plant: {},
  *   assets: []
  *  }
*/
export const encodeForApi = (plantStore) => {
  const { ...plant } = plantStore

  // Cancello i campi che non servono al backend
  delete plant.dataloggers
  delete plant.devices
  delete plant.aggregators
  delete plant.districts
  delete plant.assetTypes
  delete plant.activeStep
  delete plant.loadData

  // Estrapolo nome, possibile uuid e inserisco tutto il resto in metadata
  const { name, uuid, ...metadata } = plant
  // Sfoltisco l'array di dataloggers e degli assets associati ad una smartHome, salvando solo nome e uuid
  const addedDataloggers = metadata.addedDataloggers.map(el => ({ uuid: el.uuid, name: el.name }))
  // const addedAssets = metadata.addedAssets.map(el => ({ uuid: el.uuid, name: el.name }))

  // Se il campo non è definito, lo setto a stringa vuota
  if (!metadata.address) {
    metadata.address = ''
  }
  // modifico le coordinate in number e l'indirizzo in oggetto vuoto in caso di default sbagliati
  const newAddress = metadata.address === ''
    ? ({
        type: 'point',
        coordinates: [],
        inputValue: ''
      })
    : ({
        ...metadata.address,
        coordinates: metadata.address?.coordinates ? metadata.address?.coordinates.map(el => Number(el)) : []
      })

  // Costruisco il nuovo oggetto da mandare al backend tramite POST
  const newPlant = {
    plant: {
      name,
      metadata: {
        ...metadata,
        address: newAddress,
        isGreenEnergy: metadata.isGreenEnergy === 'true',
        addedDataloggers
      }
    },
    dataloggers: addedDataloggers.map(el => el.uuid),
    assets: metadata.addedAssets
  }

  // Se l'impianto ha un uuid lo inserisco nell'oggetto plant
  if (uuid && uuid !== undefined) {
    newPlant.plant.uuid = uuid
  }

  return newPlant
}
